h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 1em 1.3em;
}

.profile {
  display: flex;
  justify-content: space-between;
  gap: 2em;
}

.missions-profile,
.rockets-profile {
  width: 100%;
}

.missions-list,
.rockets-list {
  border: 1px solid #eee;
  padding: 1.2em 1.5em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  width: 100%;
  height: 4em;
}

.mission-name,
.rocket-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 1.25em;
}
